/**
 * @module LinearAPI
 */
import Logger from 'lib/logger';
import {
  LinearStreamControllerApi,
  LinearTVEStreamControllerApi,
  VodTveStreamControllerApi
} from '@devplat/xvp-rights-client';
import config from 'config';
import { senderDebugger } from '../../debug/sender-receiver-debug';

const STG_BASE_PATH = 'https://rights-stg.exp.xvp.na-1.xcal.tv';
const PROD_BASE_PATH = 'https://rights.exp.xvp.na-1.xcal.tv';
const BASE_PATH = config.environment.API_ENV !== 'prod' ? STG_BASE_PATH : PROD_BASE_PATH;

const QUERY_PARAM_STRINGIFY_METHOD = (query) => {
  return Object.keys(query).map((key) => key + '=' + query[key]).join('&');
};
const logger = new Logger('XVP', { background: 'blue', color: 'white' });

class RightsAPI {
  _session = null;
  _ready = false;
  _linearStreamControllerApi = null;
  _linearTVEStreamControllerApi = null;
  _vodTveStreamControllerApi = null;

  init( params = {} ) {
    this._session = params.session;
    this._tokenSummary = this._session.tokenSummary || {};
    this.environment = config.env || config.environment.ENV;
    this._requestParams = {
      rightsRequestBody: {
        xsct: this._session.xsct
      }
    };
    this._apiConfig = {
      basePath: BASE_PATH,
      xsct: this._session.xsct,
      fetchApi: window.fetch.bind(window),
      accessToken: () => {
        return this._session.serviceAccessToken;
      },
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    };
    this._ready = true;
    this._linearStreamControllerApi = this._getLinearStreamControllerApi();
    this._linearTVEStreamControllerApi = this._getLinearTVEStreamControllerApi();
    this._vodTveStreamControllerApi = this._getVodTveStreamControllerApi();
  }

  _getLinearStreamControllerApi( options = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.error('_getLinearStreamControllerApi: did not provide tokens');
      return;
    }
    return new LinearStreamControllerApi(this._apiConfig);
  }

  _getLinearTVEStreamControllerApi( options = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.error('_getLinearTVEStreamControllerApi: did not provide tokens');
      return;
    }
    return new LinearTVEStreamControllerApi(this._apiConfig);
  }

  _getVodTveStreamControllerApi( options = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.error('_getVodTveStreamControllerApi: did not provide tokens');
      return;
    }
    return new VodTveStreamControllerApi(this._apiConfig);
  }

  async putHeartbeatLinear(options = {}) {
    const paramObj = Object.assign(this._requestParams, options);
    senderDebugger.debugNetworkMessage('[XVP][API] putHeartbeatLinear using _linearStreamControllerApi:', paramObj);
    return await this._linearStreamControllerApi && this._linearStreamControllerApi.continueLinearStreamV1(paramObj);
  }

  async deleteHeartbeatLinear(options = {}) {
    const paramObj = Object.assign(this._requestParams, options);
    senderDebugger.debugNetworkMessage('[XVP][API] deleteHeartbeatLinear using _linearStreamControllerApi:', paramObj);
    return await this._linearStreamControllerApi && this._linearStreamControllerApi.stopLinearStreamV1(paramObj);
  }

  async putHeartbeatTVELinear(options = {}) {
    const paramObj = Object.assign(this._requestParams, options);
    senderDebugger.debugNetworkMessage('[XVP][API] putHeartbeatTVELinear using _linearTVEStreamControllerApi:', paramObj);
    return await this._linearTVEStreamControllerApi && this._linearTVEStreamControllerApi.continueLinearTveStreamV1(paramObj);
  }

  async deleteHeartbeatTVELinear(options = {}) {
    const paramObj = Object.assign(this._requestParams, options);
    senderDebugger.debugNetworkMessage('[XVP][API] deleteHeartbeatTVELinear using _linearTVEStreamControllerApi:', paramObj);
    return await this._linearTVEStreamControllerApi && this._linearTVEStreamControllerApi.stopLinearTveStreamV1(paramObj);
  }

  async canStreamTve(options = {}) {
    const serviceZone = options.serviceZoneType &&
      options.serviceZoneType.includes('zipcode') &&
      options.serviceZoneType + ':' + this._tokenSummary.servicePostalCode ||
      '';

    const paramObj = Object.assign(this._requestParams, {
      streamId: options.streamId,
      serviceZone,
      locatorType: options.locatorType,
      ...options
    });
    senderDebugger.debugNetworkMessage('[XVP][API] canStreamV1 using _linearTVEStreamControllerApi:', paramObj);
    return await this._linearTVEStreamControllerApi && this._linearTVEStreamControllerApi.canStreamV1(paramObj);
  }

  async startLinearTveExternal(options = {}) {
    const paramObj = Object.assign(this._requestParams, options);
    senderDebugger.debugNetworkMessage('[XVP][API] startLinearTveExternalV1 using _linearTVEStreamControllerApi:', paramObj);
    return await this._linearTVEStreamControllerApi && this._linearTVEStreamControllerApi.startLinearTveExternalV1(paramObj);
  }

  async startVodTveExternal(options = {}) {
    const paramObj = Object.assign(this._requestParams, options);
    senderDebugger.debugNetworkMessage('[XVP][API] startVodTveExternalV1 using _vodTveStreamControllerApi:', paramObj);
    return await this._vodTveStreamControllerApi && this._vodTveStreamControllerApi.startVodTveExternalV1(paramObj);
  }

  get ready() {
    return this._ready;
  }

  get linearStreamControllerApi() {
    if (!this._linearStreamControllerApi || !this.ready) {
      logger.error('linearStreamControllerApi: linearStreamControllerApi is: ' + this._linearStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._linearStreamControllerApi;
  }

  get linearTVEStreamControllerApi() {
    if (!this._linearTVEStreamControllerApi || !this.ready) {
      logger.error('linearTVEStreamControllerApi: linearTVEStreamControllerApi is: ' + this._linearTVEStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._linearTVEStreamControllerApi;
  }

  get vodTveStreamControllerApi() {
    if (!this._vodTveStreamControllerApi || !this.ready) {
      logger.error('heartbeatApi: vodTveStreamControllerApi is: ' + this._vodTveStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._vodTveStreamControllerApi;
  }
}

export default new RightsAPI();
