/**
 * @memberof module:Player
 */
import Logger from 'lib/logger';
import { getProperty } from 'lib/helpers';
import config from 'config';
import adManager from '../../constants/ad-manager-keys';

const logger = new Logger('FREEWHEEL', { background: 'white', color: 'red' });

/**
 * adds configuration values to content options
 */

export const buildManifestDAI = ({ contentOptions, deviceId }) => {
  contentOptions.adConfig = config.t6VodManifestAds ? {
    type: adManager.MANIFEST,
    terminalAddress: deviceId
  } : { type: adManager.NONE };
  logger.log('FW linear ads built: ' + JSON.stringify(contentOptions.adConfig));
};

export const buildFWVodAds = ({ contentOptions, watchable, currentTokenSummary }) => {
  contentOptions.adConfig = config.freewheelTveVODEnabled ? {
    ...getFreewheelConfig({ type: 'tve' }),
    siteSectionFallbackBrand: watchable.adBrand,
    type: adManager.FREEWHEELVOD
  }: { type: adManager.NONE };
  contentOptions.brand = watchable.adBrand;
  contentOptions.serviceZip = currentTokenSummary.servicePostalCode;
  logger.log('FW vod ads built: ' + JSON.stringify(contentOptions.adConfig));
};

export const buildFWLinearAds = ({ contentOptions, watchable, currentTokenSummary, assetEngine }) => {
  contentOptions.adConfig = config.tveLinearFreeWheelAds ? {
    assetFallbackId: getProperty(watchable.findPlayableStream(), 'adInfo.freewheelAssetNetworkId'),
    assetNetworkId: getProperty(watchable.findPlayableStream(), 'adInfo.freewheelAssetNetworkId'),
    type: assetEngine === 'helio_fusion' ? adManager.FREEWHEELINTIMELINE : adManager.TVELINEAR,
    ...getFreewheelConfig({ type: 'linear' })
  } : { type: adManager.NONE };
  contentOptions.brand = getProperty(watchable.findPlayableStream(), 'stream.adInfo.adBrand');
  contentOptions.serviceZip = currentTokenSummary.servicePostalCode;
  logger.log('FW linear ads built: ' + JSON.stringify(contentOptions.adConfig));
};
export const getFreewheelConfig = ({ type }) => {
  if (type === 'linear') {
    return Object.assign({}, config.freewheelConfigs.tveLinear, {
      networkId: config.environment.FREEWHEEL_ID_LINEAR,
      siteSectionNetworkId: config.environment.FREEWHEEL_ID_LINEAR,
      freewheelPlatform: config.environment.FREEWHEEL_PLATFORM_LINEAR,
      playerProfilePlatform: config.environment.PLAYER_PROFILE_PLATFORM_LINEAR
    });
  }
  return Object.assign({}, config.freewheelConfigs.tveVod, {
    networkId: config.environment.FREEWHEEL_ID_VOD,
    siteSectionNetworkId: config.environment.FREEWHEEL_ID_VOD,
    freewheelPlatform: config.environment.FREEWHEEL_PLATFORM_VOD,
    playerProfilePlatform: config.environment.PLAYER_PROFILE_PLATFORM_VOD
  });
};
