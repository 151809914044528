import { dispatches } from 'lib/event-dispatcher';
import api from 'api';
import heartbeatEvents from 'constants/heartbeat-events';
import ResumePoints from 'resume-points';
import featuresTypes from 'constants/xvp-ads-types';
import XVP from 'lib/xvp';
import Logger from 'lib/logger';
import { senderDebugger } from './lib/debug/sender-receiver-debug';

const logger = new Logger('Heartbeat', { background: 'deepskyblue', color: 'white' });

const heartbeatInterval = 120e3; // 2 minutes
const endpoints = {
  linear: {
    beat: 'linearStreamHeartbeat',
    stop: 'stopWatchingLinearStream'
  },
  purchase: {
    beat: 'purchasedVodStreamHeartbeat',
    stop: 'stopWatchingPurchasedVod',
    finish: 'finishWatchingPurchasedVod'
  },
  recording: {
    beat: 'recordingHeartbeat',
    stop: 'stopWatchingRecording',
    finish: 'finishWatchingRecording'
  },
  tveLinear: {
    beat: 'tveLinearStreamHeartbeat',
    stop: 'stopWatchingLinearStreamTve'
  },
  tveRecording: {
    beat: 'tveRecordingHeartbeat',
    finish: 'finishWatchingRecording',
    stop: 'stopWatchingRecording'
  },
  tveVod: {
    beat: 'tveVodStreamHeartbeat',
    stop: 'stopWatchingTveVod',
    finish: 'finishWatchingTveVod'
  },
  vod: {
    beat: 'vodStreamHeartbeat',
    stop: 'stopWatchingVod',
    finish: 'finishWatchingVod'
  }
};

/**
 * Makes XTV API heartbeat requests
 */
@dispatches('heartbeat')
class Heartbeat {
  _hearbeatInterval = null;

  _watchable = null;

  _getEndpoint(type) {
    if (this._watchable.isLinearTve()) {
      return endpoints.tveLinear[type];
    }

    if (this._watchable.isChannel() || this._watchable.isListing()) {
      return endpoints.linear[type];
    }

    if (this._watchable.isVod()) {
      return endpoints.vod[type];
    }

    if (this._watchable.isTve()) {
      return endpoints.tveVod[type];
    }

    if (this._watchable.isTveRecording()) {
      return endpoints.tveRecording[type];
    }

    if (this._watchable.isRecording()) {
      return endpoints.recording[type];
    }

    if (this._watchable.isPurchase()) {
      return endpoints.purchase[type];
    }
  }

  _getParams() {
    if (this._watchable.isLinear()) {
      return { streamId: this._watchable.getLinearProp('streamId') };
    }

    if (this._watchable.isPurchase() || this._watchable.isVod() || this._watchable.isTve()) {
      return { mediaId: this._watchable.mediaId };
    }

    if (this._watchable.isRecording()) {
      return { recordingId: this._watchable.id };
    }
  }

  _getAssetUrn() {
    if (this._watchable.isLinear()) {
      return 'merlin:linear:stream:' + this._watchable.getLinearProp('streamId');
    }

    if (this._watchable.isPurchase() || this._watchable.isVod() || this._watchable.isTve()) {
      return 'merlin:media:' + this._watchable.accountName + ':' + this._watchable.mediaGuid;
    }

    if (this._watchable.isRecording()) {
      return 'xrn:saved:recording:' + this._watchable.id;
    }
  }

  _sendHeartbeat = async () => {
    // todo: add checks/endpoints for vod
    if (XVP.getFeature(featuresTypes.xvpHeartbeats) && this._watchable.isLinear()) {
      try {
        await XVP.send({
          endPoint: this._watchable.hasTveContextType() ? 'putHeartbeatTVELinear' : 'putHeartbeatLinear',
          streamId: (this._watchable.channel || {}).streamId || this._watchable.streamId || this._watchable.listingId || ''
        });
      } catch (error) {
        if (error) {
          const response = (error.response && await error.response.json()) || {};
          const statusCode = response && response.status;
          const subCode = response && response.statusSubCode;

          logger.error(response);
          senderDebugger.debugErrorMessage('[HEARTBEAT][XVP] BEAT ERROR: '+JSON.stringify(response));

          // 403 responses (except 403-102) are fatal
          if (statusCode === 403 && subCode !== '403-102') {
            this.dispatchEvent(heartbeatEvents.failed, { response });
          }
        }
      }
    } else {
      try {
        await api.send({
          endpoint: this._getEndpoint('beat'),
          params: this._getParams()
        });
      } catch (error) {
        const xtvError = error.xhr.xtv;

        // 403 responses (except 403-102) are fatal
        if (xtvError.code === '403' && xtvError.subCode !== '102') {
          this.dispatchEvent(heartbeatEvents.failed, { error });
        }
      }
    }
  }

  /**
   * Start making heartbeat requests
   *
   * Sets an interval timer to make XTV API requests for heartbeat
   *
   * @param {object} watchable - Current watchable
   */
  start(watchable) {
    this.stop();

    this._watchable = watchable;
    this._heartbeatTimer = setInterval(this._sendHeartbeat, heartbeatInterval);
  }

  /**
   * Stop making heartbeat requests
   */
  stop() {
    this._heartbeatTimer = clearInterval(this._heartbeatTimer);
  }

  /**
   * Sends a stop watching request
   */
  async stopWatching() {
    if (!this._watchable) {
      return;
    }
    if (XVP.getFeature(featuresTypes.xvpHeartbeats) && this._watchable.isLinear()) {
      try {
        await XVP.send({
          endPoint: this._watchable.hasTveContextType() ? 'deleteHeartbeatTVELinear' : 'deleteHeartbeatLinear',
          streamId: (this._watchable.channel || {}).streamId || this._watchable.streamId || this._watchable.listingId || ''
        });
      } catch (error) {
        const response = (error.response && await error.response.json()) || {};
        logger.error(response);
        senderDebugger.debugErrorMessage('[HEARTBEAT][XVP] BEAT ERROR: '+JSON.stringify(response));
      }
    } else {
      return api.send({
        endpoint: this._getEndpoint('stop'),
        params: this._getParams()
      });
    }
  }

  /**
   * Send a finished watching request
   */
  async finishWatching() {
    if (!this._watchable) {
      return;
    }

    const endpoint = this._getEndpoint('finish');

    if (!endpoint) {
      return;
    }

    await api.send({
      endpoint,
      params: this._getParams()
    });

    // Re-cache resume points
    setTimeout(() => ResumePoints.getAllResumePoints(), 2500);
  }
}

export default new Heartbeat();
