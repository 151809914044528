
/**
 *  @memberof module:Player
 */
import Logger from 'lib/logger';
import castMessenger from 'cast-messenger';

const logger = new Logger('VIPER');

/**
  *
  * Returns a object for PlayerPlatform Analytics
  *
  * PlayerPlatform attempts to build a "Xua" asset for analytics.
  * At _least_ one of the below IDs must be provided otherwise Viper Analytics
  * will not track the user's session.
  * asset.url
  * asset.providerId
  * asset.assetId
  * asset.mediaGuid
  * asset.recordingId
  * asset.streamId
  * asset.assetType
  * @function
  * @param {string} appName
  * @param {object} config config object from app level
  * @param {object} session object containing 'tokenSummary'
  * @param {number} bitRatePolicy number indicating ABR policy 0,1,2
  * @param {number} initialBuffer value to set PP initial buffer value
  * @param {boolean} playbackQuality used in web desktop - kept to match - remove this?
  * @param {boolean} inHome user in-home state
  * @return {object} returns a bitrate number
  */
export const getHostInfo = ({ appName, config, tokenSummary, playbackQuality = false, inHomeCallback } = {}) => {
  if (!tokenSummary || !config) {
    logger.error('Missing tokenSummary and or config. Cannot configure viper analytics');
    return {};
  }
  return {
    appName: appName,
    appVersion: config.version + '.' + config.gitCommit +'-' + config.bitRatePolicy + '-' + config.initialBuffer + '-' + playbackQuality,
    deviceName: 'chromecast',
    deviceVersion: castMessenger.cast.receiver.VERSION,
    deviceId: tokenSummary.deviceId,
    accountId: tokenSummary.xboAccountId,
    xsctPartnerId: tokenSummary.partnerId,
    physicalId: tokenSummary.deviceId,
    inHomeState: inHomeCallback,
    tokenSummary: tokenSummary
  };
};
export const getHomeStatus = () => {
  return this.user.inhome ? 'inHome' : 'outOfHome';
};
/**
 * passing in a watchable this will attempt to build a viper analytics configured object
 * @param  {object} watchable asset watchable
 * @function
 * @return {object}           object configured for viper analytics to be merged with content options
 */
export const resolveAnalyticIds = ({ watchable }) => {
  const stream = watchable.findPlayableStream();
  const audId = watchable.auditudeId || stream.auditudeId || '';
  const config = {
    assetId: audId || watchable.assetId || '',
    assetType: getPlayerPlatformAssetType({ watchable: watchable }),
    auditudeId: audId,
    mediaGuid: watchable.mediaGuid || '',
    providerId: watchable.providerId || (watchable.contentProvider || {}).name || '',
    streamId: stream.streamId || ''
  };
  const streamType = stream._type || '';

  // attempt to get providerId and assetId from paid if missing only for t6 VOD
  if (!(!!watchable.assetId || !!watchable.providerId) && watchable.paid && watchable.isVod() && !watchable.isTve()) {
    Object.assign(watchable, this.resolveAssetIdFromPAID(watchable));
  }

  if (watchable.isRecording()) {
    config.recordingId = watchable.id || '';
  }

  if (watchable.isTveRecording()) {
    config.regulatoryClass = 'TVE';
  }

  if (watchable.isLinear()) {
    config.streamId = watchable.getLinearProp('streamId');
  }

  if (streamType.toLowerCase() === 'externalstream') {
    config.assetId = watchable.streamId || '';
  }


  return config;
};

export const resolveAssetIdFromPAID = ({ watchable }) => {
  const paidId = watchable.paid || '';
  const auditudeId = watchable.auditudeId || '';
  let providerId = watchable.providerId || '';
  let assetId = watchable.assetId || '';

  if (providerId && assetId) {
    return { providerId: providerId, assetId: assetId };
  }
  if (paidId) {
    providerId = providerId || paidId.split('_')[0] || '';
    assetId = assetId || paidId.split('_')[1] || '';
  }
  return {
    providerId: providerId || auditudeId,
    assetId: assetId
  };
};
/**
 * return asset type that PP would report this asset as
 * @function
 * @param  {object} watchable asset watchable
 * @return {string} string of what the asset type is
 */
export const getPlayerPlatformAssetType = ({ watchable }) => {
  const isLinear = watchable.isLinear();
  const isTve = watchable.isTve() || watchable.isLinearTve() || false;
  const isAnyVod = watchable.isAnyVod();
  const isRecording = watchable.isRecording();
  const isIvod = watchable.isIvod();
  const isPurchase = watchable.isPurchase();
  const isEspnOtt = watchable.isOTT() && /espn/ig.test((watchable.findPlayableStream() || {}).contentUrl);

  if (isLinear) {
    if (isTve) {
      return 'TVE_LINEAR';
    }
    return 'T6_LINEAR';
  }
  if (isEspnOtt) {
    return 'OTT';
  }
  if (isIvod) {
    return 'IVOD';
  }
  if (isRecording) {
    return 'CDVR';
  }
  if (isAnyVod) {
    if (isTve || isPurchase) {
      return 'TVE_VOD';
    }
    return 'T6_VOD';
  }
  return 'DEFAULT';
};
