import config from 'config';
import { generateUUID, getProperty } from 'lib/helpers';
import Timer from 'lib/helpers/timer';
import telemetry from 'lib/telemetry';
import castMessenger from 'cast-messenger';
import Logger from 'lib/logger';
import controllerEvents from 'constants/controller-events';
import featuresTypes from 'constants/xvp-ads-types';
import { checkHighBitrateSupport, getModelDeviceBestGuess } from 'lib/helpers/device-detection';
import splunkTypes from 'constants/splunk-types';
import messageTypes from 'constants/message-types';
import { playerErrorLevels, XTVAPIErrorLevels } from 'constants/error-levels';

const logger = new Logger('SPLUNK', { backgroundColor: 'peru' });

class SplunkLogger {
  _vsidLogging = {};
  constructor() {
    this.baseInfo = {
      'appname': 'stream-chromecast',
      'env': config.env,
      'module': 'XTVAPI',
      'user.appVersion': config.version,
      'user.partnerId': config.partner,
      'user.sessionId': generateUUID(),
      'user.device.bestGuess': getModelDeviceBestGuess(),
      'user.device.highBitrateSupport': checkHighBitrateSupport(),
      'url': window.location.href
    };
    this.userFeatures = {};
    this.playbackTimer = new Timer();
    this.bufferTimer = new Timer();
    this.timerToFirstFrame = new Timer();
    this.videoStartTimer = new Timer();
    this.timeToFirstFrame = 0;
    this.videoStartTime = 0;
    castMessenger.addEventListenerCollection(this.senderListeners);
  }

  controllerListeners = {
    [controllerEvents.load]: () => {
      this.timerToFirstFrame.start();
    },

    [controllerEvents.pause]: () => {
      this.playbackTimer.pause();
    },
    [controllerEvents.play]: () => {
      this.playbackTimer.resume();
    },
    [controllerEvents.unload]: ({ detail }) => {
      detail.controller.removeEventListenerCollection(this.controllerListeners);
    }
  };

  senderListeners = {
    [messageTypes.controllerCreated]: ({ detail }) => {
      detail.controller.addEventListenerCollection(this.controllerListeners);
    },
    [messageTypes.senderConnected]: ({ detail }) => {
      Object.assign(this.baseInfo, {
        'senderId': detail.senderId
      });
    }
  }

  onPlayerReady(info) {
    Object.assign(this.baseInfo, {
      'user.playerVersion': info.playerVersion
    });
  }
  setUserFeatures(featureData = {}) {
    this.userFeatures = featureData.features;
    const userFeaturesToLog =
    {
      'user.features.xvpTVGrid': this.userFeatures[featuresTypes.xvpTVGrid] || false,
      'user.features.xvpResumePoints': this.userFeatures[featuresTypes.xvpResumepoints] || false,
      'user.features.xvpHeartbeats': this.userFeatures[featuresTypes.xvpHeartbeats] || false
    };

    Object.assign(this.baseInfo, userFeaturesToLog);
  }

  onVideoAttempt(watchable, playerInfo) {
    this._vsid = generateUUID();
    this.playerInfo = playerInfo;
    this.watchable = watchable;
    this.videoStartTimer.start();
    this._vsidLogging[this._vsid] = {
      start: false,
      end: false,
      fatalError: false
    };
    this.log(this._videoInfo({ eventType: splunkTypes.Attempt, watchable }));
  }

  logAdEvent(eventType, adData) {
    if (this.timerToFirstFrame.startTime > 0) {
      this.timeToFirstFrame = this.timerToFirstFrame.end(true);
      this.timerToFirstFrame.clearTimer();

      if (this.videoStartTimer.startTime > 0) {
        this.videoStartTime = this.videoStartTimer.end(true);
        this.videoStartTimer.clearTimer();
      }
    }

    this.log(this._videoInfo({ eventType, watchable: this.watchable, adData }));
  }

  onVideoHealAttempt({ watchable, healInfo }) {
    this.log(this._videoInfo({ eventType: 'HealAttempt', watchable, healInfo }));
  }

  onPlaybackMonitorReady({ watchable, healInfo }) {
    this.log(this._videoInfo({ eventType: 'PlaybackMonitorStarted', watchable, healInfo }));
  }

  seekAfterAdAttempt({ watchable, healInfo }) {
    this.log(this._videoInfo({ eventType: 'seekAfterAdAttempt', watchable, healInfo }));
  }

  seekStart() {
    this.seekBuffer = true;
  }

  onVideoStart(watchable, loggingInfo) {
    // we are getting multiple PLAYBACK_STARTED events for single attempt.
    // avoiding logging multiple starts by checking with vsid.
    if ((this._vsidLogging[this._vsid] || {}).start) {
      this.log(this._videoInfo({ eventType: splunkTypes.Retry, watchable, loggingInfo }));
      return;
    }

    const timeToFirstFrame = this.timerToFirstFrame.startTime > 0 ? this.timerToFirstFrame.end(true) :
      (this.timeToFirstFrame || 0);
    const videoStartTime = this.videoStartTimer.startTime > 0 ? this.videoStartTimer.end(true) :
      (this.videoStartTime || 0);
    this.videoStartTimer.clearTimer();
    this.timerToFirstFrame.clearTimer();
    this.playbackTimer.start();
    this.bufferTimer.clearTimer();
    this.seekBuffer = false;
    this.loggingInfo = loggingInfo;
    (this._vsidLogging[this._vsid] || {}).start = true;
    this.log(Object.assign(
      this._videoInfo({ eventType: splunkTypes.Start, watchable, loggingInfo }),
      { videoStartTime, timeToFirstFrame }
    ));
  }

  onBufferEvent(eventType, detail) {
    // Ignore first buffer, So if playback not started return.
    if (!this.playbackTimer.startTime) {
      return;
    }

    if (this.seekBuffer) {
      this.seekBuffer = false;
      return;
    }

    let bufferingTime = 0;
    if (eventType === splunkTypes.BufferStart) {
      this.bufferTimer.resume();
    } else if (this.bufferTimer.startTime > 0) {
      bufferingTime = this.bufferTimer.pauseWithInterval();
      this.bufferTimer.startTime = 0;
    } else {
      return;
    }
    this.logBufferEvents(eventType, bufferingTime);
  }

  logBufferEvents(eventType, bufferingTime) {
    const logInfo = this._videoInfo({ eventType, watchable: this.watchable, loggingInfo: this.loggingInfo });
    this.log(Object.assign(logInfo, bufferingTime && { bufferingTime }));
  }

  onVideoEnd() {
    // Ignore end if no start, adding because shudown event can trigger without playback
    if (!this.playbackTimer.startTime) {
      return;
    }

    const playbackDuration = this.playbackTimer.end();
    const totalBufferingTime = this.bufferTimer.totalDuration;
    const logInfo = this._videoInfo({ eventType: splunkTypes.End, watchable: this.watchable, loggingInfo: this.loggingInfo });
    (this._vsidLogging[this._vsid] || {}).end = true;
    this.log(Object.assign( logInfo, { playbackDuration, totalBufferingTime }));
    this.watchable = {};
    this.loggingInfo = {};
  }

  onError(error) {
    const handle = (error.module === 'Video' || error.category === 'player') ? '_videoErrorInfo' : '_errorInfo';
    this.log(this[handle](error));
  }

  onEASEvent(type, loggingInfo, watchable) {
    this.log(this._videoInfo({ type, watchable, loggingInfo }));
  }

  onSuccess(response) {
    this.log(this._apiSuccessInfo(response));
  }

  logConnectionStatus(status, { reason='' } = {}) {
    const connectionPayload = {
      module: 'Connection',
      type: status
    };

    if (status === 'DISCONNECTED') {
      Object.assign(connectionPayload, { reason: reason });
    }

    this.log({
      ...this.baseInfo,
      ...connectionPayload
    });
  }

  log(info) {
    const messageItems = this._getMessageItems(info);
    telemetry.log('SPLUNK_LOG', messageItems.join(' '));

    this.consoleLog(info);
  }

  consoleLog(info) {
    logger.logBlock(`${info.module} ${info.type} ${info.serviceName || info.endpoint || ''}`, (logger) => {
      Object.entries(info).forEach(([key, val]) => {
        val !== undefined && logger.log(`${key}: %c%s`, 'color: peru', val);
      });
    });
  }

  setDeviceCapabilities(deviceInfo = {}) {
    this.baseInfo = {
      ...this.baseInfo,
      ...deviceInfo
    };
  }

  setSession({ serviceAccessToken, tokenSummary }) {
    Object.assign(this.baseInfo, this._sessionInfo(tokenSummary));
    telemetry._connect(serviceAccessToken);
  }

  setSenderDetails(senderDetails) {
    // Clear out any old values
    delete this.baseInfo['sender.appVersion'];
    delete this.baseInfo['sender.browser'];
    delete this.baseInfo['sender.googlePlayServices'];
    delete this.baseInfo['sender.manufacturer'];
    delete this.baseInfo['sender.os'];
    delete this.baseInfo['sender.platform'];

    if (!senderDetails) {
      logger.log('No senderDetails to set');
      return;
    }

    logger.log('Setting senderDetails:', senderDetails);
    Object.assign(this.baseInfo, {
      'sender.appVersion': senderDetails.appVersion,
      'sender.browser': senderDetails.browser,
      'sender.googlePlayServices': senderDetails.googlePlayServices,
      'sender.manufacturer': senderDetails.manufacturer,
      'sender.os': senderDetails.os,
      'sender.platform': senderDetails.platform
    });
  }

  _sessionInfo(tokenSummary) {
    // TODO user.features, user.playerVersion
    // TODO user.device.bestGuessModel
    // TODO user.device.highBitrateSupport

    return {
      'in_home': tokenSummary.inHomeStatus === 'in-home',
      'user.account_state': tokenSummary.accountState,
      'user.analyticsId': tokenSummary.analyticsId,
      'user.deviceId': tokenSummary.deviceId,
      'user.entitlements': tokenSummary.entitlements,
      'user.mso': tokenSummary.mso,
      'user.userType': tokenSummary.userType,
      'user.xboAccountId': tokenSummary.xboAccountId,
      'user.device.bestGuess': getModelDeviceBestGuess(),
      'user.device.highBitrateSupport': checkHighBitrateSupport()
    };
  }

  _apiSuccessInfo(response) {
    let successInfo = this.baseInfo;
    if (response.channels) {
      successInfo = { ...successInfo, channels: response.channels };
    }
    return {
      ...successInfo,
      endpoint: getProperty(response, 'xhr.url') || getProperty(response, 'endpoint'),
      httpStatusCode: getProperty(response, 'xhr.status') || getProperty(response, 'endpoint'),
      httpType: getProperty(response, 'xhr.type') || getProperty(response, 'type'),
      responseTime: getProperty(response, 'responseTime') || getProperty(response, 'responseTime'),
      mediaId: getProperty(response, 'request.params.mediaId') || getProperty(response, 'mediaId'),
      serviceName: getProperty(response, 'endpoint'),
      streamId: getProperty(response, 'request.params.streamId') || getProperty(response, 'request.params.streamId'),
      type: 'Success'
    };
  }

  _errorInfo(error) {
    const tvappCode = getProperty(error, 'lookup.tvapp') || '00100';
    const endpoint = error.endpoint;

    const map = Object.assign({}, this.baseInfo, {
      'endpoint': endpoint,
      'error.code': `TVAPP-${ tvappCode }`,
      'error.fingerprint': error.fingerprint,
      'error.level': this._getXTVAPIErrorLevel(error),
      'error.reason': this._getErrorMessage(error),
      'error.subcode': error.subCode || error.code,
      'httpStatusCode': error.code,
      'mediaId': getProperty(error, 'params.mediaId'),
      'responseTime': getProperty(error, 'params.responseTime'),
      'serviceName': getProperty(error, 'error.endpoint') || endpoint,
      'type': error.eventType || splunkTypes.error
    });

    return map;
  }

  _videoInfo({ eventType, watchable, loggingInfo, error, adData, healInfo }) {
    const playerInfo = this.playerInfo || {};
    const assetInfo = watchable || this.watchable;

    const map = Object.assign({}, this.baseInfo, {
      'module': 'Video',
      'type': eventType,
      'vsid': this._vsid
    });

    if (loggingInfo) {
      Object.assign(map, {
        'asset.playerEngine': loggingInfo.assetEngineType,
        'asset.resolvedStreamUrl': loggingInfo.streamUrl,
        'asset.currentlyStreamingAssetUrl': loggingInfo.currentlyStreamingUrl,
        'asset.streamingFormat': loggingInfo.urlType,
        'asset.timelineDuration': loggingInfo.duration
      });
    }

    if (adData) {
      Object.assign(map, adData);
    }

    if (error) {
      Object.assign(map, error);
    }

    if (healInfo) {
      if (healInfo.description) {
        map.description = healInfo.description;
      }
      Object.assign(map, healInfo);
    }

    if (assetInfo) {
      const channel = assetInfo.channel || {};
      const stream = (channel.streams || assetInfo.streams || [])[0] || {};

      Object.assign(map, {
        'asset.assetId': assetInfo.assetId,
        'asset.auditudeId': assetInfo.auditudeId || (assetInfo.stream || {}).auditudeId,
        'asset.contentProvider': (assetInfo.contentProvider || channel.contentProvider || {}).name,
        'asset.chromecast': getProperty(assetInfo, 'castInfo.chromecast') || getProperty(channel, 'castInfo.chromecast'),
        'asset.mediaId': assetInfo.mediaId || channel.mediaId,
        'asset.paid': assetInfo.paid,
        'asset.programId': playerInfo.programId,
        'asset.providerId': assetInfo.providerId || channel.providerId || stream.streamId,
        'asset.streamId': assetInfo.streamId || channel.streamId,
        'asset.stationId': channel.stationId,
        'asset.stream.encodingFormat': stream.encodingFormat,
        'asset.stream.streamId': stream.streamId,
        'asset.stream.streamMediaId': stream.contentUrl,
        'asset.stream._type': stream._type,
        'asset.title': this.getDetailedTitle(assetInfo),
        'asset.totalDuration': assetInfo.duration,
        'asset.type': assetInfo.getTypeLabel()
      });
    }

    return map;
  }

  _videoErrorInfo(error) {
    const tvappCode = getProperty(error, 'lookup.tvapp') || '00100';
    let level = this._getVideoErrorLevel(error);

    if ((this._vsidLogging[this._vsid] || {}).fatalError) {
      level = 'warn';
    }

    if (level === 'fatal') {
      (this._vsidLogging[this._vsid] || {}).fatalError = true;
    }

    return Object.assign(this._videoInfo({
      eventType: error.eventType || splunkTypes.error,
      watchable: error.watchable,
      loggingInfo: error.loggingInfo,
      healInfo: error.healInfo,
      error: {
        'isMidStream': error.isMidStream,
        'error.code': `TVAPP-${ tvappCode }`,
        'error.major': error.code,
        'error.minor': error.subCode,
        'error.msg': this._getErrorMessage(error),
        'error.level': level
      } }));
  }

  _getMessageItems(map={}, wrapperObjectName, entries=[]) {
    Object.keys(map).forEach((key) => {
      const val = map[key];
      if (val && Array.isArray(val)) {
        entries.push(this._serializeKeyValuePair(key, val.join(), wrapperObjectName));
      } else if (val && typeof val === 'object') {
        this._getMessageItems(val, key, entries);
      } else if (val !== undefined && val !== null && val !== '') {
        entries.push(this._serializeKeyValuePair(key, val, wrapperObjectName));
      }
    });
    return entries;
  }

  _serializeKeyValuePair(attribute, data, wrapperObjectName) {
    return (data !== undefined && data !== null) ?
      (`${wrapperObjectName ? `${wrapperObjectName}.${attribute}` : attribute}="${ data }"`) : '';
  }

  getDetailedTitle(watchable) {
    const creativeWork = watchable.creativeWork;
    if (creativeWork && creativeWork.isTvEpisode()) {
      const seriesTitle = getProperty(creativeWork, 'series.title');
      const { season, episode, title } = creativeWork;
      const hasSeason = season !== undefined;
      const hasEpisode = episode !== undefined;
      const separator = ((hasSeason || hasEpisode) && title) ? '-' : '';
      return `${ seriesTitle ? seriesTitle : '' } ${ hasSeason ? `S${ season }` : '' } ${ hasEpisode ?
        `Ep${ episode }` : '' } ${ separator } ${ title }`;
    }

    return watchable.derivedTitle;
  }
  _getXTVAPIErrorLevel(error) {
    const code = Number(error.code);
    const errorLevel = XTVAPIErrorLevels[`${error.code}.${error.subCode}`];

    if (errorLevel) {
      return errorLevel;
    }

    if (code >= 400 && code < 500 || error.fatal || getProperty(error, 'data.error.fatal')) {
      return 'fatal';
    }

    return 'warn';
  }

  _getVideoErrorLevel(error) {
    const errorLevel = playerErrorLevels[`${error.code}.${error.subCode}`];
    return errorLevel || (error.fatal ? 'fatal' : 'warn');
  }

  _getErrorMessage(error) {
    return error.msg || error.description || 'Default Error Message';
  }

  isEndLogged(vsid) {
    return (this._vsidLogging[vsid] || {}).end;
  }

  get vsid() {
    return this._vsid;
  }
}

export default new SplunkLogger();
export { SplunkLogger };
