/**
 * @module XVP
 */
import config from 'config';
import errorCategories from 'constants/error-categories';
import featuresTypes from 'constants/xvp-ads-types';
import { dispatches } from 'lib/event-dispatcher';
import Logger from 'lib/logger';
import { getXVPEndpoint } from './xvp-services';
import LinearAPI from './xvp-bff-client/linear-service';
import SessionAPI from './xvp-bff-client/session-service';
import DiscoAPI from './xvp-bff-client/disco-service';
import PlaybackAPI from './xvp-bff-client/playback-service';
import RightsAPI from './xvp-bff-client/rights-service';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';

/**
 * Singleton module for working with generated XVP clients. This object should be fed a
 * valid session (xsct, sat, deviceId, xboAccountId) before interaction with XVP.
 * Eventually auth/provisioning can occur here once the XVP session api is ready.
 * Logging, retry, error handling... middleware to be handled at this level.
 * (more docs to come as xvp grows...)
 */
const PROD_CLIENT_ID = 'stream-chromecast';
// const DEV_CLIENT_ID = 'swagger-ui';// 'stream-chromecast-dev';
const logger = new Logger('XVP', { background: 'blue', color: 'white' });
@dispatches('xvp')
class XVP {
  _session = {
    partnerId: '',
    deviceId: '',
    clientId: '',
    accountId: '',
    xboAccountId: '',
    xsct: ''
  };
  LinearAPI = LinearAPI;
  SessionAPI = SessionAPI;
  DiscoAPI = DiscoAPI;
  PlaybackAPI = PlaybackAPI;
  RightsAPI = RightsAPI;

  /**
  * Note: partnerId, clientId are also sent in the _session
  */
  constructor() {
    this.partnerId = config.partner;
    this.clientId = PROD_CLIENT_ID,
    this.environment = config.environment.ENV;
    senderDebugger.sendDebugMessage('XVP constructor hit', {
      configEnv: config.environment.ENV
    });
  }
  accessTokenResponse() {
    // TODO: error handle here
    return this._session.serviceAccessToken;
  }
  async initServiceAPI(endPoint) {
    const targetEndpoint = endPoint;
    const xvpServiceData = getXVPEndpoint(targetEndpoint);
    const servicePoint = this[xvpServiceData.endPointData.xvpServiceName];

    if (!servicePoint.ready) {
      servicePoint.init(Object.assign({}, { session: this._session, accessTokenResponse: this.accessTokenResponse.bind(this) }));

      this[xvpServiceData.endPointData.xvpServiceName] = this[xvpServiceData.endPointData.xvpServiceName] || null;
      if (!this[xvpServiceData.xvpApi]) {
        senderDebugger.sendDebugMessage('[XVP][initServiceAPI]', {
          targetEndpointRequested: targetEndpoint,
          xvpServiceDataSent: xvpServiceData,
          servicePointRequested: servicePoint,
          configEnv: config.environment.ENV,
          codeEnv: this.environment
        });

        servicePoint.init(Object.assign({}, { session: this._session, accessTokenResponse: this.accessTokenResponse.bind(this) }));
        // api props end up: this.channelsApi, this.stationApi, this.sessionApi etc
        this[xvpServiceData.xvpApi] = servicePoint[xvpServiceData.xvpApi];
      }
    }
  }
  get session() {
    return this._session;
  }

  set session(tokenSummary) {
    const token = Object.assign({}, tokenSummary, tokenSummary.tokenSummary) || tokenSummary || {};
    if (!token.xsct || !token.serviceAccessToken || !token.deviceId || !token.xboAccountId) {
      logger.log('set session failed, missing necessary session data');
      return;
    }
    senderDebugger.sendDebugMessage('[SESSION] TOKEN SESSION SET :', {
      sessionToken: Object.assign({}, token, {
        xsct: 'xsct here',
        serviceAccessToken: 'SAT here',
        playbackToken: 'playback here',
        accessToken: 'access token here'
      })
    });
    this._session = token;

    return this._session;
  }

  getFeatures() {
    return this._session && this._session.featuresResult && this._session.featuresResult.features;
  }
  getFeature(xvpFeature) {
    return Boolean(this._session.featuresResult.features[xvpFeature] === 'true') || false;
  }
  /**
   * Prepares channel error to be throw to app
   *  App.error({ category, error, code, subCode, description }) {
   * @param {string} errorType - Channel error to throw
   * @param {object} error
   * @param {object} options - optional data to be passed with errors
   * @return {object} - error obj formatted to be thrown for the app
   */
  errorFormatted({ errorType, error, options }) {
    senderDebugger.debugErrorMessage(`errorFormatted: ${ errorType }`, {
      channelId: options && options.channelId,
      xvpTVGrid: this.getFeature(featuresTypes.xvpTVGrid),
      errorDetail: error
    });
    const channelId = options ? 'ChannelId: ' + options.channelId : '';
    const listingId = options && options.listingId ? ' - ListingId: ' + options.listingId : '';// 'ListingId: unknown';
    const stationId = options && options.stationId ? ' - StationId: ' + options.stationId : '';// 'stationId: unknown';
    const errorObj = error && error.response ? {
      ok: error.response && error.response.ok,
      redirected: error.response && error.response.redirected,
      status: error.response && error.response.status,
      statusText: error.response && error.response.statusText,
      type: error.response && error.response.type,
      url: error.response && error.response.url,
      detail: error.detail || 'no details',
      stack: error.stack+ ' < /br>',
      message: error.message
    } : Object.assign({}, error, options);
    const isXVP = this.getFeature(featuresTypes.xvpTVGrid);
    const errorMap = {
      'getChannelMap': {
        category: errorCategories.api,
        code: 'getChannelMap',
        subCode: '',
        description: `Cannot get getEntitledTvGridChannels for ${ options ? options.channelId : 'unknown' } - channel map call failed`,
        endpoint: options && options.logResponse ? options.logResponse.url : 'getEntitledTvGridChannels',
        serviceName: 'getEntitledTvGridChannels',
        error: {
          category: errorCategories.api,
          code: 'api',
          subCode: 'getChannelMap',
          description: `Cannot get getEntitledTvGridChannels for ${ channelId } - channel map call failed`,
          fatal: true
        },
        isXVP: isXVP,
        errorDetail: error,
        errorResponse: errorObj
      },
      'channelMapMissingChannelId': {
        category: errorCategories.api,
        code: 'channelMapMissingChannelId',
        description: `Cannot find channelId ${ options ? options.channelId : 'unknown' } in channel map`,
        endpoint: 'internal',
        serviceName: 'channelIdNotFoundinChannelMap',
        error: {
          category: errorCategories.api,
          code: 'api',
          subCode: 'channelIdNotFoundinChannelMap',
          description: `Cannot get ${channelId}${listingId}${stationId} from within channel map`,
          fatal: true
        },
        isXVP: this.getFeature(featuresTypes.xvpTVGrid),
        errorDetail: error,
        errorResponse: errorObj
      },
      'getTvGridChunks': {
        category: errorCategories.api,
        code: 'getTvGridChunk',
        description: `Cannot get getTvGridChunks 
        ${channelId}${listingId}${stationId}- tv chunks call failed`,
        serviceName: 'getTvGridChunk',
        error: {
          category: errorCategories.api,
          code: 'api',
          subCode: 'getTvGridChunk',
          description: `Cannot get getTvGridChunks ${channelId}${listingId}${stationId} - tv chunks call failed.`,
          fatal: true
        },
        isXVP: isXVP,
        errorDetail: error,
        errorResponse: errorObj
      },
      'getEntityMetaDataForNewProgram': {
        category: errorCategories.api,
        code: 'getEntityMetaDataForNewProgram',
        description: `Cannot get getEntityMetaDataForNewProgram 
        ${channelId}${listingId}${stationId} - tv chunks call failed`,
        endpoint: options && options.logResponse ? options.logResponse.url : 'getEntityMetaDataForNewProgram',
        serviceName: 'getEntityMetaDataForNewProgram',
        error: {
          category: errorCategories.api,
          code: 'api',
          subCode: 'getEntityMetaDataForNewProgram',
          description: `Cannot get getEntityMetaDataForNewProgram ${channelId}${listingId}${stationId} - tv chunks call failed`,
          fatal: true
        },
        isXVP: false,
        errorDetail: error,
        errorResponse: errorObj
      },
      'getTvListingDetail': {
        category: errorCategories.api,
        code: 'getTvListingDetail',
        description: `Cannot get getTvListingDetail ${channelId}${listingId}${stationId} - tv chunks call failed`,
        endpoint: options && options.logResponse ? options.logResponse.url : 'getTvListingDetail',
        serviceName: 'getTvListingDetail',
        error: {
          category: errorCategories.api,
          code: 'api',
          subCode: 'getTvListingDetail',
          description: `Cannot get getTvListingDetail ${channelId}${listingId}${stationId} - tv chunks call failed`,
          fatal: true
        },
        isXVP: isXVP,
        errorDetail: error,
        errorResponse: errorObj
      }
    };
    senderDebugger.debugErrorMessage(`errorFormatted: ${ errorType }`, {
      channelId: options && options.channelId,
      xvpTVGrid: this.getFeature(featuresTypes.xvpTVGrid),
      errorObjCreated: errorMap[errorType],
      errorDetail: error
    });
    return errorMap[errorType];
  }
  /**
   * Send XVP request using endpoint defined in supportedResources
   * @param {object} params - request params
   * @return {Promise}
   */
  async send(params) {
    const endPoint = params.endPoint;
    const xvpServiceData = getXVPEndpoint(endPoint);
    const xvpService = this[xvpServiceData.endPointData.xvpServiceName];
    const session = this.session;
    const tokenSummary = session.tokenSummary || {};
    let logResponse = {};
    let rawXVPResponse = undefined;

    senderDebugger.debugNetworkMessage(`[XVP][API] Endpoint: ${endPoint} PRE-SEND CALLED:`, {
      endPointRequested: endPoint,
      paramsSent: params,
      xvpService: xvpServiceData,
      codeCurrentEnv: this.environment
    });

    if (!xvpService.ready || !this[xvpServiceData.xvpApi]) {
      this.initServiceAPI(endPoint);
    }
    const endPointData = xvpServiceData.endPointData.endPoints;
    let xvpMethodData = endPointData.find((pointResource) => {
      return pointResource.xtvEndpoint === endPoint;
    });
    if (!xvpMethodData) {
      xvpMethodData = endPointData.find((pointResource) => {
        return pointResource.xvpApiCall === endPoint;
      });
    }
    const resource = endPoint && xvpService;

    if (!resource || !this.accessTokenResponse()) {
      logger.log('XVP pre-send authTokenfailed, missing session/sat token');
      return;
    }

    const requiredParams = {
      clientId: this.clientId,
      partnerId: this.partnerId,
      accountId: tokenSummary.xboAccountId,
      deviceId: tokenSummary.deviceId
    };
    const opts = Object.assign({}, requiredParams, params);

    senderDebugger.debugNetworkMessage(`[XVP][API] Endpoint:${endPoint} SEND CALLED:`, {
      endPointRequested: endPoint,
      paramsSent: params,
      xvpService: xvpServiceData,
      codeCurrentEnv: this.environment,
      xvpServiceName: xvpServiceData.endPointData.xvpServiceName,
      xvpApiServiceCall: xvpMethodData.xvpApiCall
    });

    return await this[xvpServiceData.endPointData.xvpServiceName][xvpMethodData.xvpApiCall]( // request override params here...
      opts
    ).then(async (response) => {
      senderDebugger.debugNetworkMessage('ATTEMPT JSON XVP RESPONSE RAW:', response);
      // need to handle end point if not used with 'raw' xvp api calls
      if (response && response.raw) {
        rawXVPResponse = response.raw.clone();

        if (response.raw.clone) {
          logResponse = response.raw.clone();
        }
        if (logResponse.json) {
          return await logResponse.json();
        }
      }

      return response;
    }).then(async (response) => {
      if (rawXVPResponse) {
        return rawXVPResponse;
      }
      return response;
    });
  }
}

export default new XVP();
export { XVP };
