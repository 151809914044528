import Logger from 'lib/logger';
import Session from 'lib/session';
import telemetry from 'lib/telemetry';
import splunkLogger from 'lib/telemetry/splunk-logger';
import player from 'player';
import authTokenProvider from 'lib/auth-token-provider';
import siftTracker from 'sift';
import XVP from 'lib/xvp/';

const logger = new Logger('CAST-SESSION', { background: 'plum' });

class CastSession {
  constructor({ sessionId, senderId, xsct, xboAccountId, senderDetails, partnerId }) {
    logger.log('Creating new session:', sessionId);
    this.sessionId = sessionId;
    this.senderId = senderId;
    this.xsct = xsct;
    this.senderPartnerId = partnerId;
    this.xboAccountId = xboAccountId;
    this.senderDetails = senderDetails;
    this.parentalControls = {};
    this.senderConfig = {};
    this.captureDeviceCapabilities();
  }

  updateParentalControls(updates) {
    Object.entries(updates)
      .filter(([key, value]) => value !== undefined)
      .forEach(([key, value]) => this.parentalControls[key] = value);

    // logger.log('updated parental controls', this.senderId, this.parentalControls);
  }

  updateSenderConfig(config) {
    this.senderConfig = { ...this.senderConfig, ...config };
    // logger.log('updated sender config', this.senderId, this.senderConfig);
  }

  async _doAuth() {
    // logger.log('authenticating sender', this.senderId);
    this.session = await Session.getSession(this.xsct, this.senderId, this.xboAccountId, this.senderPartnerId);
  }

  async auth() {
    if (!this.authPromise) {
      this.authPromise = this._doAuth();
    }

    return this.authPromise;
  }

  async activate() {
    await this.auth();

    // logger.log('activating session', this.sessionId);
    authTokenProvider.setToken({ xsct: this.session.xsct });

    if (this.session.featuresResource) {
      try {
        const featuresResult = await this.session.featuresResource.fetch();
        this.session.featuresResult = featuresResult.data ? featuresResult.data.getProps() : {};
      } catch (error) {
        this.session.featuresResult = {};
      }
    }

    telemetry.setUserData(this.session); // TODO telemetry gets sat token from authTokenProvider?
    splunkLogger.setUserFeatures(this.session.featuresResult);
    splunkLogger.setSession(this.session);
    splunkLogger.setSenderDetails(this.senderDetails);
    splunkLogger.setDeviceCapabilities(this.deviceCapabilities);


    player.setSession(this.session);
    player.setDeviceCapabilities(this.deviceCapabilities);
    XVP.session = this.session;
    siftTracker.onSessionActivated(this.session, this.senderDetails);

    this.inHome = await Session.getInHome();

    this.currentPostalCode = await Session.getCurrentPostalCode();
    /**
     * AAEWT-14812: disable accurate grid stations until API is ready to release.
     * Uncomment below line then.
     * this.currentPostalCode && await Session.updateChannelMapCache(this.currentPostalCode);
     */
    player.setCurrentPostalCode(this.currentPostalCode);
  }

  captureDeviceCapabilities() {
    try {
      const context = window.cast.framework.CastReceiverContext.getInstance();
      this.deviceCapabilities = context.getDeviceCapabilities();
    } catch (e) {
      this.deviceCapabilities = null;
    }
  }

  get autoPlayEnabled() {
    return !!this.senderConfig.autoPlayEnabled;
  }
}

export default CastSession;
