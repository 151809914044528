/**
 * @module PlaybackAPI
 */
import Logger from 'lib/logger';
import { ResumepointsApi } from '@devplat/xvp-playback-client';
import config from 'config';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';

const SWAGGER_CLIENT_ID = 'swagger-ui';
const STG_BASE_PATH = 'https://playback-stg.exp.xvp.na-1.xcal.tv';
const PROD_BASE_PATH = 'https://playback.exp.xvp.na-1.xcal.tv';
const BASE_PATH = config.environment.API_ENV !== 'prod' ? STG_BASE_PATH : PROD_BASE_PATH;

const QUERY_PARAM_STRINGIFY_METHOD = (query) => {
  return Object.keys(query).map((key) => key + '=' + query[key]).join('&');
};
const logger = new Logger('XVP-PlaybackAPI', { background: 'blue', color: 'white' });

class PlaybackAPI {
  _session = null;
  _ready = false;
  _resumepointsApi = null;

  get ready() {
    return this._ready;
  }
  constructor() {
    console.log('  playback constructor here? ');
  }
  init( params = {} ) {
    // TODO error check on session config data
    this._session = params.session;
    this.environment = config.env || config.environment.ENV;
    this._ready = true;
    this._resumepointsApi = this._getResumepointsApi();
  }

  async getResumePoints(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    return await this._resumepointsApi && this._resumepointsApi.getResumePoints({
      partnerId: tokenSummary.partnerId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      limit: 1000
    });
  }

  async createUpdateResumePoint(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      entityType: options.entityType,
      entityId: options.entityId,
      resumePointRequest: {
        ownerReference: 'xrn:subscriber:device:' + tokenSummary.deviceId,
        progressMillis: options.progressMillis || 0,
        mediaAccountName: options.mediaAccountName || '',
        mediaGuid: options.mediaGuid || ''
      }
    };
    senderDebugger.debugNetworkMessage('[XVP][API] createUpdateResumePoint using _resumepointsApi options:', options);
    senderDebugger.debugNetworkMessage('[XVP][API] createUpdateResumePoint using _resumepointsApi:', paramObj);
    return await this._resumepointsApi && this._resumepointsApi.createUpdateResumePoint(paramObj);
  }

  _getResumepointsApi( options = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.error('_getResumepointsApi: did not provide tokens');
      return;
    }
    return new ResumepointsApi({
      basePath: BASE_PATH,
      xsct: this._session.xsct,
      fetchApi: window.fetch.bind(window),
      accessToken: () => {
        return this._session.serviceAccessToken;
      },
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    });
  }

  get resumepointsApi() {
    if (!this._resumepointsApi || !this.ready) {
      logger.error('resumepointsApi: resumepointsApi is: ' + this._resumepointsApi +
    ' Playback Class Service ready is: ' + this.ready );
      return null;
    }
    return this._resumepointsApi;
  }
}

export default new PlaybackAPI();
